import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

import {
  create,
  update,
} from '../../../store/personSlice';
import {
  getSellers,
} from '../../../store/sellerSlice';
import {
  getJobs,
} from '../../../store/jobSlice';
import {
  getRoles,
} from '../../../store/roleSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  person_name: '',
  person_lastname: '',
  person_seller_id: null,
  person_job_id: null,
  person_email: '',
  person_phone: '',

  new_password_p: '',
  new_password_confirm_p: '',
  person_hubspot_contact: '',
  person_rol_id: null,
  person_state: null,
};

const schema = (type) => yup.object().shape({
  person_name: yup.string().required('Nombres es requerido'),
  person_lastname: yup.string().required('Apellidos es requerido'),
  person_seller_id: yup.object().required('Vendedor es requerida'),
  // person_job_id: yup.object().required('Cargo es requerida'),
  person_rol_id: yup.object().required('Rol es requerida'),
  person_email: yup
    .string()
    .email('Email no válido')
    .required('Email es requerido'),
  person_phone: yup
    .string()
    .notRequired() // No requerido por defecto
    .when('person_phone', {
      is: (value) => value && value.length > 0, // Si está lleno, aplica validaciones
      then: (schema) =>
        schema
          .matches(/^[0-9]+$/, 'Celular debe contener solo números')
          .min(12, 'Celular debe tener al menos 12 dígitos incluyendo el indicativo del país')
          .max(15, 'Celular debe tener un máximo de 15 dígitos incluyendo el indicativo del país'),
      otherwise: (schema) => schema, // No hace nada si está vacío
    }),
  person_state: yup.object().required('Estado es requerida'),
  // person_documento: yup
  //   .string()
  //   .matches(/^\d{7}$|^\d{10}$/, 'Documento debe tener 7 o 10 dígitos')
  //   .required('Documento es requerido'),
  // new_password_p: yup
  //   .string()
  //   .notRequired()
  //   .optional()
  //   .when('new_password_confirm_p', (new_password_confirm_p, field) =>
  //     new_password_confirm_p && new_password_confirm_p.length > 0 && new_password_confirm_p[0] !== ''
  //       ? field.required('La nueva contraseña es requerida')
  //         .min(6, 'Contraseña debe tener al menos 6 caracteres')
  //         .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
  //         .matches(/\d/, 'Contraseña debe tener al menos un número')
  //         .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
  //       : field
  //   ),
  // new_password_confirm_p: yup
  //   .string()
  //   .notRequired()
  //   .optional()
  //   .oneOf([yup.ref('new_password_p'), ''], 'Las contraseñas deben coincidir')
  //   .when('new_password_p', (new_password_p, field) =>
  //     new_password_p && new_password_p.length > 0 && new_password_p[0] !== ''
  //       ? field.required('Confirmar la nueva contraseña es requerida')
  //       : field
  //   ),
  new_password_p: yup
    .string()
    .when([], (fields, schema) =>
      type === 'create'
        ? schema.required('La nueva contraseña es requerida')
          .min(6, 'Contraseña debe tener al menos 6 caracteres')
          .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
          .matches(/\d/, 'Contraseña debe tener al menos un número')
          .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
        : schema.notRequired().optional()
          .when('new_password_confirm_p', (new_password_confirm_p, field) =>
            new_password_confirm_p && new_password_confirm_p.length > 0 && new_password_confirm_p[0] !== ''
              ? field.required('La nueva contraseña es requerida')
                .min(6, 'Contraseña debe tener al menos 6 caracteres')
                .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
                .matches(/\d/, 'Contraseña debe tener al menos un número')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
              : field
          ),
    ),
  new_password_confirm_p: yup
    .string()
    .when([], (fields, schema) =>
      type === 'create'
        ? schema.required('Confirmar la nueva contraseña es requerida').oneOf([yup.ref('new_password_p')], 'Las contraseñas deben coincidir')
        : schema.notRequired().optional()
          .oneOf([yup.ref('new_password_p'), ''], 'Las contraseñas deben coincidir')
          .when('new_password_p', (new_password_p, field) =>
            new_password_p && new_password_p.length > 0 && new_password_p[0] !== ''
              ? field.required('Confirmar la nueva contraseña es requerida')
              : field
          )
    ),
}, [['new_password_p', 'new_password_confirm_p'], ['person_phone', 'person_phone']]);

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  const sellers = useSelector(({ seller }) => seller.sellersList);
  const jobs = useSelector(({ job }) => job.jobsList);
  const roles = useSelector(({ role }) => role.rolesList);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema(type)), // Pasar el 'type' al esquema
  });
  const { reset, control, formState, handleSubmit } = methods;
  const { errors } = formState;

  const [values, setValues] = useState({
    showPasswordNew: false,
    showPasswordConfirm: false,
  });

  const initDialog = useCallback(async () => {
    reset({
      ...item,
      person_seller_id: {
        label: `${item.fk_seller?.seller_name}`,
        value: item.person_seller_id,
      },
      person_rol_id: {
        label: `${item.fk_user?.fk_role?.role_description}`,
        value: item.fk_user?.user_rol_id,
      },
      person_job_id: item.person_job_id === null || item.person_job_id === '' ? null : {
        label: `${item.fk_job?.job_description}`,
        value: item.person_job_id,
      },
      new_password_p: '',
      new_password_confirm_p: '',
      person_state: {
        label: item.person_state === 0 ? "Inactivo" : "Activo",
        value: item.person_state,
      },
    });
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getSellers());
      await dispatch(getJobs());
      await dispatch(getRoles());
    }
    init();
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    reset(defaultValues);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (params) => () => {
    setValues({
      ...values,
      [params]: !values[params],
    })
  }
  // --------------------------------------------
  const statusOptions = [
    { label: 'Inactivo', value: 0 },
    { label: 'Activo', value: 1 }
  ];

  const optionRoles = roles
    ? roles.map((e) => ({
      value: e.role_id,
      label: e.role_description,
    }))
    : [];

  const optionSellers = sellers
    ? sellers.map((e) => ({
      value: e.seller_id,
      label: e.seller_name,
    }))
    : [];

  const optionJobs = jobs
    ? jobs.map((e) => ({
      value: e.job_id,
      label: e.job_description,
    }))
    : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        person_seller_id: data.person_seller_id.value,
        person_rol_id: data.person_rol_id.value,
        person_job_id: data.person_job_id ? data.person_job_id.value : null,
        person_state: data.person_state.value,
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Person creada', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }

    } else {
      const response = await dispatch(update({
        ...data,
        person_seller_id: data.person_seller_id.value,
        person_rol_id: data.person_rol_id.value,
        person_job_id: data.person_job_id ? data.person_job_id.value : null,
        person_state: data.person_state.value,
      },
        item.person_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Person actualizada', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Person
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
              name="person_seller_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Vendedor"
                  id="person_seller_id"
                  value={value || null}
                  options={optionSellers}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.person_seller_id}
                  helperText={errors?.person_seller_id?.message}
                  required
                  disabled={type === 'create' ? false : true}
                />
              )}
            />
            <Controller
              name="person_rol_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Rol"
                  id="person_rol_id"
                  value={value || null}
                  options={optionRoles}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.person_rol_id}
                  helperText={errors?.person_rol_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="person_name"
                  label="Nombres"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.person_name}
                  helperText={errors?.person_name?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_lastname"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="person_lastname"
                  label="Apellidos"
                  // value={form.cli_documento}
                  error={!!errors.person_lastname}
                  helperText={errors?.person_lastname?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_email"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="person_email"
                  label="Email"
                  placeholder="your@email.com"
                  // value={form.person_email}
                  // onChange={onChangeText('cli_email')}
                  error={!!errors.person_email}
                  helperText={errors?.person_email?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_phone"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="person_phone"
                  label="Celular"
                  // value={form.person_phone}
                  error={!!errors.person_phone}
                  helperText={errors?.person_phone?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_state"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Estado"
                  id="person_state"
                  value={value || null}
                  options={statusOptions}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.person_state}
                  helperText={errors?.person_state?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_job_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Cargo"
                  id="person_job_id"
                  value={value || null}
                  options={optionJobs}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.person_job_id}
                  helperText={errors?.person_job_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="person_hubspot_contact"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="person_hubspot_contact"
                  label="Hubspot contact id"
                  // value={form.person_phone}
                  error={!!errors.person_hubspot_contact}
                  helperText={errors?.person_hubspot_contact?.message}
                  required
                />
              )}
            />
          </div>
          <div className=''>
            <p className="text-16 font-bold my-2">Seguridad</p>
            <Controller
              name="new_password_p"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  type={values.showPasswordNew ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword(
                            "showPasswordNew"
                          )}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPasswordNew ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="new_password_p"
                  autocomplete={false}
                  label="Nueva contraseña"
                  error={!!errors.new_password_p}
                  helperText={errors?.new_password_p?.message}
                  required
                />
              )}
            />
            <Controller
              name="new_password_confirm_p"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  type={values.showPasswordConfirm ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword(
                            "showPasswordConfirm"
                          )}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPasswordConfirm ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autocomplete={false}
                  id="new_password_confirm_p"
                  label="Confirmar contraseña"
                  error={!!errors.new_password_confirm_p}
                  helperText={errors?.new_password_confirm_p?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
