import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import md5 from 'md5';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Tabs, Tab, Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomUploadFile from '@components/CustomUploadFile';
import CustomTableHead from '@components/CustomTableHead';

import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';

import {
  create,
  update,
  setCleanFile
} from '../../../store/serviceSlice';
import {
  getServicesGroup
} from '../../../store/serviceGroupSlice';
import {
  getCountries
} from '../../../store/stateSlice';
import { showMessage } from '../../../store/messageSlice';

import UploadFileTab from './UploadFileTab';
import ActivitiesTab from './ActivitiesTab';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  popper: {
    '& .MuiPopper-root': { zIndex: 9999 },
  },
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 700,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
}));

const defaultValues = {
  service_name: '',
  service_value: '',
  service_group_id: null,
  service_country_id: null,
  service_hubspot_sku: '',
  service_lead_time: '',
};

const schema = yup.object().shape({
  service_name: yup.string().required('Nombre es requerido'),
  service_value: yup
    .string()
    .required('Valor es requerido')
    .test('is-decimal', 'El número debe ser válido', value => {
      if (!value) return false;
      // Reemplaza el punto por nada y la coma por un punto
      const normalizedValue = value.replace(/\./g, '').replace(/,/g, '');
      return /^\d+(\.\d+)?$/.test(normalizedValue);
    })
    .transform(value => {
      // Reemplazar el formato de coma para manejarlo como número decimal
      return value ? value.replace(/\./g, '.').replace(/,/g, ',') : value;
    }),
  service_group_id: yup.object().required('Grupo es requerida'),
  service_country_id: yup.object().required('Pais es requerida'),
  service_hubspot_sku: yup.string().required('Hubspot SKU es requerido'),
  service_lead_time: yup.string().required('Tiempo entrega es requerido'),
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  //-------------------------------------------
  const countries = useSelector(({ state }) => state.countriesList);
  const groups = useSelector(({ serGroup }) => serGroup.servicesGroupList);
  //-------------------------------------------
  const [loading, setLoading] = useState(false);
  const [mainId, setMainId] = useState(null);
  const [valueTab, setValueTab] = useState(0);
  //-------------------------------------------
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { reset, control, formState, handleSubmit, setValue } = methods;
  const { errors } = formState;
  //-------------------------------------------
  const initDialog = useCallback(async () => {
    reset({
      ...item,
      service_group_id: {
        label: `${item.fk_service_group?.group_name}`,
        value: item.service_group_id,
      },
      service_country_id: {
        label: item.fk_country?.country_name,
        value: item.service_country_id,
      }
    });
    setMainId(item.service_id);
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getCountries());
      await dispatch(getServicesGroup());
    }
    init();

    return async () => {
      await dispatch(setCleanFile())
    }
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = () => {
    setMainId(null);
    reset(defaultValues);
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };
  // --------------------------------------------
  const optionGroups = groups
    ? groups.map((e) => ({
      value: e.group_id,
      label: e.group_name,
    }))
    : [];

  const optionCountries = countries
    ? countries.map((e) => ({
      value: e.country_id,
      label: e.country_name,
    }))
    : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        service_country_id: data.service_country_id.value,
        service_group_id: data.service_group_id.value,
      }));
      if (response) {
        setMainId(response.seac_id)
        await dispatch(showMessage({ message: 'Service creada', variant: 'success' }));
        setLoading(false);
        handleRefresh();
      } else {
        setLoading(false);
      }
    } else {
      const response = await dispatch(update({
        ...data,
        service_country_id: data.service_country_id.value,
        service_group_id: data.service_group_id.value,
      },
        item.service_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Service actualizada', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} className={classes.dialog}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Service
        </p>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <div className="grid md:grid-cols-1 sm:grid-cols-1 grid-cols-1 gap-3">
              <div>
                <Controller
                  name="service_name"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      multiline
                      rows={3}
                      maxRows={3}
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      id="service_name"
                      label="Nombre"
                      // value={form.cli_nombre_empresa}
                      error={!!errors.service_name}
                      helperText={errors?.service_name?.message}
                      required
                    />
                  )}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
              <div>
                <Controller
                  name="service_country_id"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <CustomAutocomplete
                      styles={{ mb: 2 }}
                      label="Pais"
                      id="service_country_id"
                      value={value || null}
                      options={optionCountries}
                      onChange={(event, newValue) => {
                        onChange(event);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.key}>
                          {option.label}
                        </li>
                      )}
                      error={!!errors.service_country_id}
                      helperText={errors?.service_country_id?.message}
                      required
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="service_group_id"
                  control={control}
                  render={({ field: { onChange, value, onBlur, ref } }) => (
                    <CustomAutocomplete
                      styles={{ mb: 2 }}
                      label="Grupo de servicio"
                      id="service_group_id"
                      value={value || null}
                      options={optionGroups}
                      onChange={(event, newValue) => {
                        onChange(event);
                      }}
                      renderOption={(props, option) => (
                        <li {...props} key={option.key}>
                          {option.label}
                        </li>
                      )}
                      error={!!errors.service_group_id}
                      helperText={errors?.service_group_id?.message}
                      required
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="service_value"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      styles={{ mt: 0, mb: 2, width: '100%' }}
                      id="service_value"
                      label="Valor"
                      // value={form.cli_documento}
                      error={!!errors.service_value}
                      helperText={errors?.service_value?.message}
                      required
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="service_hubspot_sku"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      id="service_hubspot_sku"
                      label="Hubspot SKU"
                      // value={form.cli_nombre_empresa}
                      error={!!errors.service_hubspot_sku}
                      helperText={errors?.service_hubspot_sku?.message}
                      required
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name="service_lead_time"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      {...field}
                      styles={{ mt: 1, mb: 2, width: '100%' }}
                      id="service_lead_time"
                      label="Tiempo entrega"
                      // value={form.cli_nombre_empresa}
                      error={!!errors.service_lead_time}
                      helperText={errors?.service_lead_time?.message}
                      required
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex justify-end items-center">
              <p
                className="mx-4 underline text-base text-gray-600 cursor-pointer"
                onClick={() => {
                  handleClose();
                  setLoading(false);
                  cleanData();
                }}
              >
                Cancel
              </p>
              {loading ? (
                <CircularProgress
                  style={{ color: '#4575D5', marginRight: 4 }}
                  size={24}
                />
              ) : (
                <CustomButton
                  label="Guardar"
                  typebutton={2}
                  onClick={handleSubmit(handleAction)}
                />
              )}
            </div>
          </div>
        </FormProvider>
        {/* ---------------------- */}
        {(mainId !== null) && (
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valueTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Archivos" {...a11yProps(0)} />
                <Tab label="Actividades" {...a11yProps(1)} />
                {/* <Tab label="Otro" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <TabPanel value={valueTab} index={0}>
              <UploadFileTab mainId={mainId} />
            </TabPanel>
            <TabPanel value={valueTab} index={1}>
              <ActivitiesTab mainId={mainId} />
            </TabPanel>
            {/* <TabPanel value={valueTab} index={2}>
              Contenido de otro
            </TabPanel> */}
          </Box>
        )}
        {/* ---------------------- */}
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
