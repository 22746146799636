
import { showMessage } from '../store/messageSlice';

export async function errorResponse(dispatch, error, functionName) {
    // console.log('error http ===', error)
    if (error?.response?.data?.message) {
        await dispatch(showMessage({ message: `${error?.response?.data?.message}`, variant: 'error' }));
    } else {
        await dispatch(showMessage({ message: `${error.message}`, variant: 'error' }));
    }
    console.info(`*** REDUX -> ${functionName} ***`)
    return false
}
