import { Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import React from 'react';
// import { CheckedIcon, UncheckedIcon } from '../FuseSvgIcon';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

const GreenCheckbox = withStyles({
  root: {
    color: '#1FD0B0',
    '&$checked': {
      color: '#1FD0B0',
    },
  },

  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CustomCheckbox(props) {
  const { rounded, label, className, helperText, error, required, styles } = props;

  return (
    <FormControl error={error} required  sx={{ ...styles }}>
      <FormControlLabel
        label={label}
        control={
          <GreenCheckbox
            {...props}
            icon={rounded && <RadioButtonUncheckedRoundedIcon />}
            checkedIcon={rounded && <CheckCircleRoundedIcon />}
          />
        }
      />
      {helperText && (
        <FormHelperText style={{ margin: 0 }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomCheckbox;
