import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { errorResponse } from '../utils/httpResponse'

export const login = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.authenticate}`;
    const result = await axios.post(URL, data);
    return result.data
  } catch (error) {
    return await errorResponse(dispatch, error, 'login')
  }
};

// -----------------------------------------------------------
// -----------------------------------------------------------
// Borrar despues
export const getAllService = (id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.clientes}/servicios/${id}`;
    const result = await axios.get(URL);
    await dispatch(setServiceList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAllPartner *** ${error}`);
  }
};

export const getAllActivities = (clse_id) => async (dispatch) => {
  try {
    const queryString = `?id_cliente_servicios=${clse_id}`;
    const URL = `${API.baseUrl}/${API.endpoints.clientes}/actividades`;
    const result = await axios.get(URL + queryString);
    await dispatch(setActivityList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAllPartner *** ${error}`);
  }
};

export const createPayment = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.clientes}/pagos`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createPartner *** ${error}`);
  }
};

export const updateActivity = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.clientes}/actividades`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createPartner *** ${error}`);
  }
};

export const updateService = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.clientes}/servicios`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createPartner *** ${error}`);
  }
};

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.document_types}/lista`;
    const result = await axios.get(URL);
    await dispatch(setDocumentsList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getDocumentTypes *** ${error}`);
  }
};

export const getCities = () => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.cities}/lista`;
    const result = await axios.get(URL);
    await dispatch(setCitiesList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getCities *** ${error}`);
  }
};
// -----------------------------------------------------------
// -----------------------------------------------------------

const userSlice = createSlice({
  name: 'user',
  initialState: {
    enabledIntegration: false,
    user: {},
    serviceList: [],
    activityList: [],
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCleanUser: (state) => {
      state.user = {};
    },
    setServiceList: (state, action) => {
      state.serviceList = action.payload;
    },
    setActivityList: (state, action) => {
      state.activityList = action.payload;
    },

    setDocumentsList: (state, action) => {
      state.documentsList = action.payload;
    },
    setCitiesList: (state, action) => {
      state.citiesList = action.payload;
    },
  }
});

export const { setUser, setServiceList, setActivityList, setCleanUser, setDocumentsList, setCitiesList } =
  userSlice.actions;

export default userSlice.reducer;
