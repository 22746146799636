import {
    Paper,
    Popper,
    Grow,
    ClickAwayListener,
    MenuList,
    MenuItem,
    IconButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    textMenu: {
        fontSize: 14,
        color: '#747B87',
        marginLeft: 8,
        marginTop: 5,
    },
    buttonMenu: {
        '&:hover': {
            background: 'none'
        }
    },
}));

function CustomClickAwayListener(props) {

    const classes = useStyles();
    const { menuTable, anchorEl, handleAwayClose, menuOptions } = props;

    return (
        <Popper open={menuTable} anchorEl={anchorEl} transition style={{ zIndex: 99999 }}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper id="menu-list-grow" style={{ boxShadow: '0px 0px 8px rgba(143, 163, 233, 0.5)' }} >
                        <ClickAwayListener onClickAway={handleAwayClose('')}>
                            <MenuList>
                                {/* <MenuItem onClick={handleAwayClose('editar')}>
                                    <FuseSvgIcon
                                        className="text-48 font-semibold"
                                        size={20}
                                        color="#651DFF"
                                    >
                                        heroicons-outline:pencil
                                    </FuseSvgIcon>
                                    <span className={classes.textMenu}>Editar</span>
                                </MenuItem> */}
                                {menuOptions.map((option) => (
                                    <MenuItem key={option.action} onClick={handleAwayClose(option.action)}>
                                        <IconButton className={classes.buttonMenu}>
                                            {option.icon} {/* Icono dinámico */}
                                            <span className={classes.textMenu}>{option.label}</span>
                                        </IconButton>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};

export default CustomClickAwayListener;
