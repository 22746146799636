import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import clsx from "clsx";
import { format, parseISO, differenceInMilliseconds, differenceInDays } from 'date-fns'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Link,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FilterRoundedIcon from '@mui/icons-material/FilterRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

import CustomTableHead from '@components/CustomTableHead';
import CustomActionDialog from '@components/CustomActionDialog';
import CustomClickAwayListener from '@components/CustomClickAwayListener';

import DownloadFileDialog from './DownloadFileDialog';
import ViewFileDialog from './ViewFileDialog';
import ObservationDialog from './ObservationDialog';

import { showMessage } from '../../../store/messageSlice';
import { getActivityBosanetById, setCleanEmployeeTrack, saveReceptionInfo, approveReport } from '../../../store/employeeTrackingSlice';

const useStyles = makeStyles({
  dialog: {
    // width: "100%",
    // backgroundColor: 'rgba(80, 80, 80, .5)',
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 800,
      width: '100%',
    },
    // "& .MuiDialog-paperWidthMd": {
    //   maxWidth: 600,
    //   width: '100%',
    //   // backgroundColor: 'white',
    // },
  },
});

const headCells = [
  {
    id: 'ass_service_id',
    order: false,
    label: 'Dias del servicio',
  },
  {
    id: 'ass_status',
    order: false,
    label: 'Porcentaje'
  },
  {
    id: 'ass_start_date',
    order: false,
    label: 'Fecha ejecución'
  },
  {
    id: 'ass_planned_date',
    order: false,
    label: 'Fecha estimada'
  },
  {
    id: 'ass_end_date',
    order: false,
    label: 'Fecha finalizado',
  },
];

const headCellsTwo = [
  {
    id: 'assd_id',
    order: false,
    label: 'Estado'
  },
  {
    id: 'ass_status',
    order: false,
    label: 'Actividad'
  },
  {
    id: 'assd_execution_date',
    order: false,
    label: 'Fecha ejecución',
  },
  {
    id: 'action',
    order: false,
    label: 'Archivos'
  },
];

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const authUser = useSelector(({ user }) => user.user);
  const activity = useSelector(({ employeeTrack }) => employeeTrack.dataById);
  const [items, setItems] = useState({});
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'ass_id',
  });
  const [openDownloadFile, setOpenDownloadFile] = useState(false);
  const [openViewFile, setOpenViewFile] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [dialogAction, setDialogAction] = useState(false);
  const [openObservation, setOpenObservation] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (!isEmpty(activity)) {

        if (activity.ass_start_date !== null && activity.ass_planned_date !== null) {
          let percentage;
          if (activity.ass_end_date !== null) {
            percentage = 100;

          } else {
            const fecha_inicial = parseISO(`${activity.ass_start_date}`);
            const fecha_final = parseISO(`${activity.ass_planned_date}`);
            // Usa la fecha actual sin formatearla
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Para eliminar las horas
            // Cálculo del tiempo total entre fecha inicial y final
            const totalTime = differenceInMilliseconds(fecha_final, fecha_inicial);
            // Cálculo del tiempo transcurrido desde la fecha inicial hasta hoy
            const elapsedTime = differenceInMilliseconds(today, fecha_inicial);

            // Calcula el porcentaje
            const progress = (elapsedTime / totalTime) * 100;
            // console.log('progress:', progress);

            // Cálculo del tiempo total entre fecha inicial y final en días
            // const totalTime2 = differenceInDays(fecha_final, fecha_inicial);
            // console.log('Total de días:', totalTime2);

            // Cálculo del tiempo transcurrido desde la fecha inicial hasta hoy en días
            // const elapsedTime2 = differenceInDays(today, fecha_inicial);
            // console.log('Días transcurridos:', elapsedTime2);

            // Ajuste del porcentaje para que esté entre 0% y 100%
            if (today < fecha_inicial) {
              percentage = 0;
            } else if (today > fecha_final) {
              percentage = 100;
            } else {
              percentage = progress;
            }
          }
          setItems({ ...activity, percentage });
        } else {
          setItems({ ...activity, percentage: -1 });
        }
      }
    };
    fetch();
  }, [activity]);

  useEffect(() => {
    async function init() {
      await dispatch(getActivityBosanetById(item.sese_seller_id, item.sese_service_id));
    }
    init();

    return async () => {
      await dispatch(setCleanEmployeeTrack())
    }
  }, [open]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  const toggleDialogClose = () => {
    setSelectRow(null);
    setOpenDownloadFile(!openDownloadFile);
  };

  const toggleDialogUploadClose = () => {
    setSelectRow(null);
    setOpenViewFile(!openViewFile);
  };

  const toggleObservationDialogClose = () => {
    setSelectRow(null);
    setOpenObservation(!openObservation);
  };

  const handleInfo = (data) => async () => {
    const response = await dispatch(saveReceptionInfo({
      type: data.type === 'approve' ? 'approve' : 'reject',
      assd_id: data?.assd_id,
      service_id: data?.fk_services_activities?.seac_service_id,
      assd_activity_service_seller_id: data?.assd_activity_service_seller_id
    }));
    if (response) {
      if (data.type === 'approve') {
        await dispatch(showMessage({ message: 'Aprobado con exito', variant: 'success' }));
      } else {
        await dispatch(showMessage({ message: 'Rechazado con exito', variant: 'success' }));
      }
      handleRefreshInfo()
    }
  }

  const handleRefreshInfo = async () => {
    await dispatch(getActivityBosanetById(item.sese_seller_id, item.sese_service_id));
  };

  const handleAction = (data) => async () => {
    const response = await dispatch(approveReport({
      type: data.type === 'approve' ? 'approve' : 'reject',
      assd_id: data?.assd_id
    }));
    if (response) {
      if (data.type === 'approve') {
        await dispatch(showMessage({ message: 'Informe aprobado con exito', variant: 'success' }));
      } else {
        await dispatch(showMessage({ message: 'Informe rechazado con exito', variant: 'success' }));
      }
      handleRefreshInfo()
    }
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      // style={{
      //   boxShadow: '0px 2px 16px 4px rgba(38, 41, 64, 0.1)',
      //   zIndex: 99,
      // }}
      className={classes.dialog}
    // minWidth="xl"
    // maxWidth="xl"
    >
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          Detalle servicio
        </p>
        <IconButton key="close" aria-label="Close" onClick={() => handleClose()} style={{ position: 'absolute', right: 16 }}>
          <CloseRoundedIcon style={{ color: '#213462' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent /* sx={{ width: '600px' }} */>
        {/* ------------ */}
        <div className="mt-4">
          <div className='mb-5'>
            <p className="text-16 font-bold my-2 flex">
              <BusinessCenterRoundedIcon style={{ color: '#213462', marginRight: 3 }} />
              Información
            </p>
            <div className="mb-0">
              <p className="text-14">Servicio: <span>({item.sese_service_id}) - {item.fk_service?.service_name}</span></p>
            </div>
          </div>
          {/* </div> */}
          <div className="mb-0">
            <div className="table-responsive mb-10">
              <Table className="simple mt-4">
                <CustomTableHead
                  order={order.direction}
                  orderBy={order.id}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {!isEmpty(items) ? (
                    <TableRow>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.fk_service?.service_lead_time} dias</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.percentage >= 0 ? `${items.percentage.toFixed(2)}%` : ""}</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.ass_start_date ? format(parseISO(`${items.ass_start_date}`), "dd-MM-yyyy") : null}{' '}</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.ass_planned_date ? format(parseISO(`${items.ass_planned_date}`), "dd-MM-yyyy") : null}{' '}</TableCell>
                      <TableCell style={{ fontSize: 13 }} sx={{ p: '15px 3px' }}>{items.ass_end_date ? format(parseISO(`${items.ass_end_date}`), "dd-MM-yyyy") : null}{' '}</TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ padding: 16, fontSize: 12 }}
                        colSpan={4}
                      >
                        No se han encontrado datos
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
        {/* ------------ */}
        <div className="mt-4">
          <p className="text-16 font-bold my-2 flex">
            <LibraryBooksRoundedIcon style={{ color: '#213462', marginRight: 3 }} />
            Actividades
          </p>
          <TableContainer>
            <Table size="small" className="simple mt-4">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCellsTwo}
              />
              <TableBody>
                {!isEmpty(items) && items.activities_service_seller_detail.length > 0 ?
                  items.activities_service_seller_detail.map((row) => (
                    <TableRow
                      key={row.assd_id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell sx={{ p: '15px 10px 15px 3px' }}>
                        <p
                          className={clsx(
                            'inline-flex justify-center items-center px-14 py-1 rounded-full tracking-wide uppercase whitespace-nowrap',
                            row.assd_status === 1 &&
                            'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                            (row.assd_status === 2) &&
                            'bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-50',
                            (row.assd_status === 3) &&
                            'bg-pink-100 text-pink-800 dark:bg-pink-600 dark:text-pink-50',
                            (row.assd_status === 4 || row.assd_status === 7 || row.assd_status === 8) &&
                            'bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50',
                            (row.assd_status === 5) &&
                            'bg-orange-100 text-orange-800 dark:bg-orange-600 dark:text-orange-50',
                            (row.assd_status === 6) &&
                            'bg-purple-100 text-purple-800 dark:bg-purple-600 dark:text-grey-50',
                          )}
                          style={{ fontSize: 10, maxWidth: 100 }}
                        >
                          {row.assd_status === 2 ? 'En progreso' :
                            row.assd_status === 3 ? 'Validando' :
                              row.assd_status === 4 ? 'Terminado' :
                                row.assd_status === 5 ? 'Requiere cambio' :
                                  row.assd_status === 6 ? 'Modificado' :
                                    row.assd_status === 7 ? 'Aprobado' :
                                      row.assd_status === 8 ? 'Enviado' :
                                        'Pendiente'}
                        </p>
                      </TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.fk_services_activities?.seac_name}</TableCell>
                      <TableCell sx={{ p: '15px 10px 15px 3px' }} style={{ fontSize: 12 }}>{row.assd_execution_date}</TableCell>
                      <TableCell
                        align="left"
                        sx={{ p: '14px 3px' }}
                      >
                        {/* SOLICITUD DE INFORMACIÓN AL CLIENTE */}
                        {row.assd_step === 1 && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                            setOpenDownloadFile(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id })
                          }}>Descargar archivos</Link>
                        )}

                        {/* RECEPCIÓN DE LA INFORMACIÓN DEL CLIENTE */}
                        {row.assd_step === 2 && (row.assd_status === 8 || row.assd_status === 7) && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                            setOpenViewFile(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id, step: 2, status: 7 })
                          }}>Ver archivos</Link>
                        )}

                        {/* VALIDACIÓN Y APROBACIÓN DE LA INFORMACIÓN DEL CLIENTE */}
                        {row.assd_step === 3 && row.assd_status === 3 && (
                          <div className='flex'>
                            <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={handleInfo({ ...row, user_id: item.fk_seller?.persons?.person_user_id, type: 'approve' })}>Aprobar</Link>
                            &nbsp;|&nbsp;
                            <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={handleInfo({ ...row, user_id: item.fk_seller?.persons?.person_user_id, type: 'reject' })}>Rechazar</Link>
                          </div>
                        )}

                        {/* EJECUCIÓN DEL SERVICIO */}
                        {row.assd_step === 4 && row.assd_observation && row.assd_observation === 1 && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer whitespace-nowrap" onClick={() => {
                            setOpenObservation(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id, question: 'supplier' })
                          }}>Observación supplier</Link>
                        )}

                        {/* VALIDACIÓN Y APROBACIÓN DEL INFORME FINAL	 */}
                        {row.assd_step === 5 && (row.assd_status === 8 || row.assd_status === 7) && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                            setOpenViewFile(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_id, step: 5, status: 7 })
                          }}>Ver informe</Link>
                        )}

                        {/* VALIDACIÓN Y APROBACIÓN DEL INFORME FINAL	 */}
                        {row.assd_step === 6 && row.assd_status === 3 && (
                          <div className='flex'>
                            <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={handleAction({ ...row, type: 'approve' })}>Aprobar</Link>
                            &nbsp;|&nbsp;
                            <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={handleAction({ ...row, type: 'reject' })}>Rechazar</Link>
                          </div>
                        )}

                        {/* VALIDACIÓN Y APROBACIÓN DEL INFORME FINAL	 */}
                        {row.assd_step === 7 && row.assd_observation && row.assd_observation === 1 && (
                          <Link color="#213462" className="text-12 font-bold cursor-pointer" onClick={() => {
                            setOpenObservation(true);
                            setSelectRow({ ...row, user_id: item.fk_seller?.persons?.person_user_i, question: 'seller' })
                          }}>Observación seller</Link>
                        )}

                      </TableCell>
                    </TableRow>
                  )) :
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 12 }}
                      colSpan={5}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* ------------ */}
        <div className="flex justify-end my-4 items-center">
          <p
            className="mx-4 underline text-base text-gray-600 cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            Cerrar
          </p>
        </div>
        {/* ------------ */}
        {openDownloadFile && (
          <DownloadFileDialog
            item={selectRow}
            open={openDownloadFile}
            handleClose={toggleDialogClose}
            type=""
          />
        )}
        {openViewFile && (
          <ViewFileDialog
            item={selectRow}
            open={openViewFile}
            handleClose={toggleDialogUploadClose}
            handleRefresh={handleRefreshInfo}
            type=""
          />
        )}
        {/* {dialogAction && (
          <CustomActionDialog
            open={dialogAction}
            icon={
              <HelpOutlineRoundedIcon
                style={{ color: '#213462', fontSize: 40, marginBottom: 8 }}
              />
            }
            title={
              <div
                className="font-semibold text-40 mb-4 text-center"
                color="#213462"
              >
                Aprobación de archivos
              </div>
            }
            description={
              <div
                className="text-40 mb-4 text-center"
                color="#213462"
              >
                ¿Está seguro de aprobar todos los archivos enviados?
              </div>
            }
            titleCancel={'No'}
            titleSuccess={'Si'}
            handleAction={handleAction}
            close={() => setDialogAction(false)}
          />
        )} */}
        {openObservation && (
          <ObservationDialog
            item={selectRow}
            open={openObservation}
            handleClose={toggleObservationDialogClose}
            handleRefresh={handleRefreshInfo}
            type=""
          />
        )}
        {/* ------------ */}
      </DialogContent>
    </Dialog >
  );
}

export default CustomDialog;
