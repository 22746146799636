import { Typography } from '@mui/material';
import { showMessage } from '../../store/messageSlice';
import { isEmpty } from 'lodash';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import {
    IconButton,
} from '@mui/material';

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

const CustomUploadFile = (props) => {
    const dispatch = useDispatch();
    const { value, handleUploadChange, accept, disabled, required, handleRemoveFile, multiple } = props;

    const { getRootProps, getInputProps } = useDropzone({
        // accept:  'image/jpeg, image/png, .doc, .docx, .xlsx, application/pdf',
        accept:
            accept !== undefined
                ? accept
                : {
                    'image/*': ['.jpg', '.jpeg', '.png'],
                    'application/pdf': ['.pdf'],
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                        '.doc',
                        '.docx',
                    ],
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
                },

        maxSize: 5000000,
        disabled: disabled,
        onDropRejected: async () => {
            await dispatch(
                showMessage({
                    message: `Los archivos que adjuntes no deben superar los 5MB`,
                    variant: 'error',
                })
            );
        },
        onDrop: (acceptedValue) => handleUploadChange(acceptedValue),
        multiple: multiple
    });

    return (
        <div>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {multiple && (
                    <div
                        style={{
                            borderColor: required ? '#d32f2f' : '#BDD7EF',
                            backgroundColor: disabled ? '#F7F8FC' : '#fff',
                            cursor: disabled ? 'auto' : 'pointer',
                        }}
                        className="rounded-8 border-dashed  border-2 w-288 "
                    >
                        {value && value.length === 0 ? (
                            <div className="flex justify-center items-center" style={{ height: 100, width: '100%' }}>
                                <Typography className="text-14 my-12 mx-24 text-center" style={{ color: '#7F9BB4' }}>
                                    <span className="font-bold">Suelta el archivo </span>
                                    aquí o <span className="font-bold"> haz clic</span> para adjuntar
                                </Typography>
                            </div>
                        ) : (
                            <div
                                className="flex flex-col items-center"
                                style={{
                                    // whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    minHeight: 100
                                }}
                            >
                                {value.map((file, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            position: "relative", // Necesario para el botón flotante
                                            display: "inline-block",
                                            margin: "8px",
                                            // border: "1px solid #ccc",
                                            // borderRadius: "4px",
                                            // overflow: "hidden", // Evita que el botón salga del contenedor
                                            // width: "100px", // Ajusta el tamaño del contenedor
                                            // height: "100px",
                                        }}
                                    >
                                        {/* <img
                                            src={file.preview}
                                            alt={file.name}
                                            style={{
                                                width: "100px",
                                                height: "100px",
                                                objectFit: "cover",
                                            }}
                                        /> */}
                                        {file.name}
                                        <IconButton key="close" aria-label="Close" onClick={(event) => {
                                            event.stopPropagation(); // Evita la propagación del evento a la dropzone
                                            handleRemoveFile(file.id === 'new' ? index : file.id)
                                        }}
                                            style={{ position: 'absolute', right: -24, top: 0, padding: 0, /*background: "#213462"*/ }}>
                                            <DeleteRoundedIcon style={{ color: '#213462' }} />
                                        </IconButton>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                {!multiple && (
                    <div
                        style={{
                            borderColor: required ? '#d32f2f' : '#BDD7EF',
                            maxHeight: 100,
                            height: 100,
                            backgroundColor: disabled ? '#F7F8FC' : '#fff',
                            cursor: disabled ? 'auto' : 'pointer',
                        }}
                        className="flex justify-center items-center rounded-8 border-dashed  border-2 w-288 "
                    >
                        {isEmpty(value) ? (
                            <div>
                                <Typography className="text-14 my-12 mx-24 text-center" style={{ color: '#7F9BB4' }}>
                                    <span className="font-bold">Suelta el archivo </span>
                                    aquí o <span className="font-bold"> haz clic</span> para adjuntar
                                </Typography>
                            </div>
                        ) : (
                            <div
                                className="flex flex-col items-center"
                                style={{
                                    // whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    // overflow: 'hidden',
                                    // minHeight: 100
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        display: "inline-block",
                                        margin: "8px",
                                    }}
                                >
                                    {value?.file?.name}
                                    <IconButton key="close" aria-label="Close" onClick={(event) => {
                                        event.stopPropagation(); // Evita la propagación del evento a la dropzone
                                        handleRemoveFile(value?.file?.name)
                                    }}
                                        style={{ position: 'absolute', right: -24, top: 0, padding: 0 }}>
                                        <DeleteRoundedIcon style={{ color: '#213462' }} />
                                    </IconButton>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomUploadFile;
