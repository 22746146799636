import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';
import { errorResponse } from '../utils/httpResponse'

// *** ROL BOSANET ***
export const getAll = (userId, page, per_page, orderBy, order, params) => async (dispatch) => {
    try {
        const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
        const URL = `${API.baseUrl}/${API.endpoints.employees}/service`;
        const result = await axios.get(URL + queryString);
        await dispatch(setDataList(result.data));
        return result;
    } catch (error) {
        return await errorResponse(dispatch, error, 'getAll')
    }
};

const employeeServicesSlice = createSlice({
    name: 'employeeServ',
    initialState: {
        enabledIntegration: false,
        dataList: [],
        page: 0,
        rowsPerPage: 10,
        total: 0,
    },
    reducers: {
        setCleanEmployeeServ: (state) => {
            state.dataById = {};
        },
        setDataList: (state, action) => {
            state.dataList = action.payload.rows;
            state.total = action.payload.count;
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
    }
});

export const { setCleanSupplierServ, setDataList, setRowsPerPage, setPage, setTotalRows,
 } =
 employeeServicesSlice.actions;

export default employeeServicesSlice.reducer;
