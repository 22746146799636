import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { format, parseISO } from 'date-fns'
import clsx from "clsx";

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import ManageHistoryRoundedIcon from '@mui/icons-material/ManageHistoryRounded';

import CustomLoading from '@components/CustomLoading';
import CustomTableHead from '@components/CustomTableHead';
import CustomTablePagination from '@components/CustomTablePagination';
import CustomButton from '@components/CustomButton';

import CustomDialog from './CustomDialog';
import { getAll, setRowsPerPage, setPage } from '../../../store/supplierServicesSlice';

const headCells = [
  {
    id: 'sese_id',
    order: true,
    label: 'Id'
  },
  {
    id: 'sese_seller_id',
    order: true,
    label: 'Vendedor'
  },
  {
    id: 'sese_service_id',
    order: true,
    label: 'Servicio'
  },
  {
    id: 'sese_payment_date',
    order: true,
    label: 'Fecha'
  },
  {
    id: 'sese_status',
    order: true,
    label: 'Estado'
  },
  {
    id: 'action',
    order: false,
    label: 'Ejecución'
  },
];

function Index() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authUser = useSelector(({ user }) => user.user);
  const rows = useSelector(({ supplierServ }) => supplierServ.dataList);
  const page = useSelector(({ supplierServ }) => supplierServ.page);
  const rowsPerPage = useSelector(({ supplierServ }) => supplierServ.rowsPerPage);
  const totalRows = useSelector(({ supplierServ }) => supplierServ.total);

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(0);
  const [order, setOrder] = useState({
    direction: 'desc',
    id: 'sese_id',
  });
  const [addDialog, setAddDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState('');

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await dispatch(
        getAll(authUser?.user_id, page, rowsPerPage, order.id, order.direction, {})
      );
      setLoading(false);
    };
    fetch();
  }, [dispatch, page, rowsPerPage, order.id, order.direction]);

  useEffect(() => {
    const fetch = async () => {
      if (rows && rows.length > 0) {
        setItems(rows);
      }
    };
    fetch();
  }, [rows]);

  /**
   * Handles the table sort request.
   * @param {string} property - Property to sort the table by.
   */
  const handleRequestSort = (event, property) => {
    const isAsc = order.id === property && order.direction === 'asc';
    setOrder({
      direction: isAsc ? 'desc' : 'asc',
      id: property,
    });
  };

  /**
  * Handles page change in table pagination.
  * @param {number} newPage - New selected page.
  */
  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };
  /**
   * Handles the change in the number of rows per page in the table pagination.
   * @param {Object} event - React event object.
   */
  const handleChangeRowsPerPage = (event) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  const toggleDialogCreate = () => {
    setAddDialog(!addDialog);
  };

  const toggleDialogEdit = () => {
    setEditDialog(!editDialog);
  };

  const handleRefresh = async () => {
    await dispatch(
      getAll(authUser?.user_id, page, rowsPerPage, order.id, order.direction, {})
    );
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center mt-4 bg-white p-4 rounded-lg h-16">
        <p className="text-xl font-semibold">{t('supplier_sellers_service')}</p>
        {/* <CustomButton
          label="Nuevo"
          typebutton={2}
          icon={<AddRoundedIcon fontSize="large" />}
          onClick={toggleDialogCreate}
        /> */}
      </div>
      <div className="mt-7 bg-white px-2 py-6 rounded-lg flex flex-col">
        <div className="bg-red flex justify-end pb-4 px-4">
          <div className="flex">
            <CustomTablePagination
              page={page}
              count={totalRows}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
        {loading ? (
          <CustomLoading />
        ) : (
          <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <CustomTableHead
                order={order.direction}
                orderBy={order.id}
                onRequestSort={handleRequestSort}
                headCells={headCells}
              />
              <TableBody>
                {items && items.length > 0 ? (
                  items.map((e) => {
                    return (
                      <TableRow
                        hover
                        key={e.sese_id}
                      >
                        <TableCell sx={{ p: '15px 3px' }}>{e.sese_id}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_seller?.seller_name}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{e.fk_service?.service_name}</TableCell>
                        <TableCell sx={{ p: '15px 3px' }}>{format(parseISO(`${e.sese_payment_date}`), "dd-MM-yyyy")}{' '}</TableCell>
                        <TableCell sx={{ p: '15px 10px 15px 3px' }}>
                          <p
                            className={clsx(
                              'inline-flex justify-center items-center px-14 py-1 rounded-full tracking-wide uppercase whitespace-nowrap',
                              e.sese_status === 1 &&
                              'bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50',
                              (e.sese_status === 2) &&
                              'bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-50',
                              (e.sese_status === 3) &&
                              'bg-green-100 text-green-800 dark:bg-green-600 dark:text-green-50',
                              (e.sese_status === 4) &&
                              'bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-50',
                              (e.sese_status === 5) &&
                              'bg-purple-100 text-purple-800 dark:bg-purple-600 dark:text-grey-50',
                            )}
                            style={{ fontSize: 10, maxWidth: 100 }}
                          >
                            {e.sese_status === 2 ? 'En curso' :
                              e.sese_status === 3 ? 'Finalizado' :
                                e.sese_status === 4 ? 'Cancelado' :
                                  e.sese_status === 5 ? 'Suspendido' :
                                    'Pendiente'}
                          </p>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ p: '14px 3px' }}
                        >
                          <IconButton
                            onClick={() => {
                              setItemSelect(e);
                              setAddDialog(true)
                            }}
                          >
                            <ManageHistoryRoundedIcon
                              style={{ color: '#213462' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ padding: 16, fontSize: 18 }}
                      colSpan={10}
                    >
                      No se han encontrado datos
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div className="flex justify-end mt-5 px-3">
          <CustomTablePagination
            page={page}
            count={totalRows}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {addDialog && (
        <CustomDialog
          item={itemSelect}
          handleClose={toggleDialogCreate}
          handleRefresh={handleRefresh}
          open={addDialog}
          type="create"
        />
      )}
    </div>
  );
}

export default Index;
