import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';

export const getJobs = () => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.jobs}/lista`;
    const result = await axios.get(URL);
    await dispatch(setJobsList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getJobs *** ${error}`);
  }
};

const jobSlice = createSlice({
  name: 'job',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    jobsList: [],
  },
  reducers: {
    setDataList: (state, action) => {
      state.dataList = action.payload;
    },
    setJobsList: (state, action) => {
      state.jobsList = action.payload;
    },
  }
});

export const { setDataList, setJobsList } =
jobSlice.actions;

export default jobSlice.reducer;
