import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton,
  InputAdornment
} from '@mui/material';

import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import CustomButton from '@components/CustomButton';
import CustomTextField from '@components/CustomTextField';
import CustomAutocomplete from '@components/CustomAutocomplete';

import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

import {
  create,
  update,
} from '../../../store/supplierSlice';
import {
  getDocumentTypes,
} from '../../../store/documentTypeSlice';
import {
  getCities,
  setCleanCity
} from '../../../store/citySlice';
import {
  getCountries,
  getStateId,
  setCleanState
} from '../../../store/stateSlice';
import { showMessage } from '../../../store/messageSlice';

/**
 * Transition is a function that creates a transition component using React's forwardRef function.
 *
 * @param {object} props - The properties of the Transition component.
 * @param {React.Ref} ref - Ref that is passed to the Slide component.
 * @returns {React.Component} - Transition component using Slide.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultValues = {
  supplier_company: '',
  supplier_name: '',
  supplier_lastname: '',
  supplier_email: '',
  supplier_phone: '',
  supplier_state: null,
  supplier_address: '',

  new_password_p: '',
  new_password_confirm_p: '',

  supplier_document: '',
  supplier_document_type_id: null,

  supplier_country_id: null,
  supplier_city_id: null,
  supplier_state_id: null,
};

const schema = (type) => yup.object().shape({
  supplier_company: yup.string().required('Empresa es requerido'),
  supplier_name: yup.string().required('Nombres es requerido'),
  supplier_lastname: yup.string().required('Apellidos es requerido'),
  supplier_email: yup.string().email('Email no válido').required('Email es requerido'),
  supplier_state: yup.object().required('Estado es requerida'),
  supplier_country_id: yup.object().required('Pais es requerida'),

  supplier_phone: yup
    .string()
    .notRequired() // No requerido por defecto
    .when('supplier_phone', {
      is: (value) => value && value.length > 0, // Si está lleno, aplica validaciones
      then: (schema) =>
        schema
          .matches(/^[0-9]+$/, 'Celular debe contener solo números')
          .min(12, 'Celular debe tener al menos 12 dígitos incluyendo el indicativo del país')
          .max(15, 'Celular debe tener un máximo de 15 dígitos incluyendo el indicativo del país'),
      otherwise: (schema) => schema, // No hace nada si está vacío
    }),
  new_password_p: yup
    .string()
    .when([], (fields, schema) =>
      type === 'create'
        ? schema.required('La nueva contraseña es requerida')
          .min(6, 'Contraseña debe tener al menos 6 caracteres')
          .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
          .matches(/\d/, 'Contraseña debe tener al menos un número')
          .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
        : schema.notRequired().optional()
          .when('new_password_confirm_p', (new_password_confirm_p, field) =>
            new_password_confirm_p && new_password_confirm_p.length > 0 && new_password_confirm_p[0] !== ''
              ? field.required('La nueva contraseña es requerida')
                .min(6, 'Contraseña debe tener al menos 6 caracteres')
                .matches(/[A-Z]/, 'Contraseña debe tener al menos una letra mayúscula')
                .matches(/\d/, 'Contraseña debe tener al menos un número')
                .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Contraseña debe tener al menos un carácter especial')
              : field
          ),
    ),
  new_password_confirm_p: yup
    .string()
    .when([], (fields, schema) =>
      type === 'create'
        ? schema.required('Confirmar la nueva contraseña es requerida').oneOf([yup.ref('new_password_p')], 'Las contraseñas deben coincidir')
        : schema.notRequired().optional()
          .oneOf([yup.ref('new_password_p'), ''], 'Las contraseñas deben coincidir')
          .when('new_password_p', (new_password_p, field) =>
            new_password_p && new_password_p.length > 0 && new_password_p[0] !== ''
              ? field.required('Confirmar la nueva contraseña es requerida')
              : field
          )
    ),
}, [['new_password_p', 'new_password_confirm_p'], ['supplier_phone', 'supplier_phone']]);

/**
 * Props for the CustomDialog component.
 * @property {boolean} open - Determines whether the dialog is open or closed.
 * @property {Function} handleClose - Callback function to handle the closing of the dialog.
 * @property {Function} handleRefresh - Callback function to handle the closing of the dialog.
 * @property {string} type - The type of operation ('create' or 'edit') the dialog is performing.
 * @property {Object} item - Object containing information about the partner being edited.
 */
function CustomDialog(props) {
  const { open, handleClose, type, item, handleRefresh } = props;
  const dispatch = useDispatch();

  const documents = useSelector(({ docType }) => docType.documentsList);
  const cities = useSelector(({ city }) => city.citiesList);
  const countries = useSelector(({ state }) => state.countriesList);
  const states = useSelector(({ state }) => state.statesList);

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema(type)), // Pasar el 'type' al esquema
  });
  const { reset, setValue, getValues, control, onChange, formState, handleSubmit } = methods;
  const { errors } = formState;

  const [values, setValues] = useState({
    showPasswordNew: false,
    showPasswordConfirm: false,
  });

  const initDialog = useCallback(async () => {
    reset({
      ...item,
      new_password_p: '',
      new_password_confirm_p: '',
      supplier_state: {
        label: item.supplier_state === 0 ? "Inactivo" : "Activo",
        value: item.supplier_state,
      },
      supplier_city_id: item.supplier_city_id === null || item.supplier_city_id === '' ? null : {
        label: `${item.fk_city?.city_name}`,
        value: item.supplier_city_id,
      },
      supplier_document_type_id: item.supplier_document_type_id === null || item.supplier_document_type_id === '' ? null : {
        label: item.fk_document_type?.document_type,
        value: item.supplier_document_type_id,
      },
      supplier_state_id: item.supplier_city_id === null || item.supplier_city_id === '' ? null : {
        label: item.fk_city?.fk_state?.state_name,
        value: item.fk_city?.fk_state?.state_id,
      },
      supplier_country_id: item.supplier_country_id === null || item.supplier_country_id === '' ? null : {
        label: item.fk_country?.country_name,
        value: item.fk_country?.country_id,
      },
    });
    if (item.supplier_country_id !== null) {
      await dispatch(getStateId(item.fk_country?.country_id)); // country_id
    }
    if (item.supplier_city_id !== null) {
      await dispatch(getCities(item.fk_city?.fk_state?.state_id)); // state_id
    }
  });

  useEffect(() => {
    const fetch = async () => {
      if (type === 'edit') {
        initDialog();
      }
    };
    fetch();
  }, [type, reset, item]);

  useEffect(() => {
    async function init() {
      await dispatch(getDocumentTypes());
      await dispatch(getCountries());
    }
    init();
  }, []);

  /**
  * Function to clean the information
  */
  const cleanData = async () => {
    reset(defaultValues);
    await dispatch(setCleanState())
    await dispatch(setCleanCity())
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = (params) => () => {
    setValues({
      ...values,
      [params]: !values[params],
    })
  }

  // --------------------------------------------
  const statusOptions = [
    { label: 'Inactivo', value: 0 },
    { label: 'Activo', value: 1 }
  ];

  const optionDocuments = documents
    ? documents.map((e) => ({
      value: e.document_id,
      label: e.document_type,
    }))
    : [];

  const optionCities = cities
    ? cities.map((e) => ({
      value: e.city_id,
      label: e.fullName,
    }))
    : [];

  const optionCountries = countries
    ? countries.map((e) => ({
      value: e.country_id,
      label: e.country_name
    }))
    : [];

  const optionStates = states
    ? states.map((e) => ({
      value: e.state_id,
      label: e.state_name
    }))
    : [];
  // --------------------------------------------

  /**
   * Function to send the new partner information.
   * Checks if the 'PartnersName' field is empty and displays an error if so, otherwise closes the modal.
   * @returns {void} - Returns nothing.
   */
  const handleAction = async (data) => {
    setLoading(true);
    if (type === 'create') {
      const response = await dispatch(create({
        ...data,
        supplier_state: data.supplier_state.value,
        supplier_document_type_id: data.supplier_document_type_id ? data.supplier_document_type_id.value : null,
        supplier_city_id: data.supplier_city_id ? data.supplier_city_id.value : null,
        supplier_country_id: data.supplier_country_id.value,
      }));
      if (response) {
        await dispatch(showMessage({ message: 'Supplier creada', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }
    } else {
      const response = await dispatch(update({
        ...data,
        supplier_state: data.supplier_state.value,
        supplier_document_type_id: data.supplier_document_type_id ? data.supplier_document_type_id.value : null,
        supplier_city_id: data.supplier_city_id ? data.supplier_city_id.value : null,
        supplier_country_id: data.supplier_country_id.value,
      },
        item.supplier_id
      ));
      if (response) {
        await dispatch(showMessage({ message: 'Supplier actualizada', variant: 'success' }));
        cleanData();
        setLoading(false);
        handleClose();
        handleRefresh();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} TransitionComponent={Transition}>
      <DialogTitle className="bg-gray-100 flex items-center justify-center rounded-t-lg">
        <p className="font-bold text-xl">
          {type === 'create' ? 'Crear' : 'Editar'} Supplier
        </p>
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <FormProvider {...methods}>
          <div className="mt-4 flex flex-col">
            <Controller
              name="supplier_company"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="supplier_company"
                  label="Empresa"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.supplier_company}
                  helperText={errors?.supplier_company?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_name"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="supplier_name"
                  label="Nombres"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.supplier_name}
                  helperText={errors?.supplier_name?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_lastname"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="supplier_lastname"
                  label="Apellidos"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.supplier_lastname}
                  helperText={errors?.supplier_lastname?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_country_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="País"
                  id="supplier_country_id"
                  value={value || null}
                  options={optionCountries}
                  onChange={async (event, newValue) => {
                    onChange(event);
                    setValue("supplier_state_id", null, { shouldValidate: true });
                    setValue("supplier_city_id", null, { shouldValidate: true });
                    await dispatch(getStateId(event?.value));
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.supplier_country_id}
                  helperText={errors?.supplier_country_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_state_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Departamento"
                  id="supplier_state_id"
                  value={value || null}
                  options={optionStates}
                  onChange={async (event, newValue) => {
                    setValue("supplier_city_id", null, { shouldValidate: true });
                    onChange(event);
                    if (event) {
                      await dispatch(getCities(event?.value));
                    }
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.supplier_state_id}
                  helperText={errors?.supplier_state_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_city_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Ciudad"
                  id="supplier_city_id"
                  value={value || null}
                  options={optionCities}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.supplier_city_id}
                  helperText={errors?.supplier_city_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_document_type_id"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mb: 2 }}
                  label="Tipo de documento"
                  id="supplier_document_type_id"
                  value={value || null}
                  options={optionDocuments}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.supplier_document_type_id}
                  helperText={errors?.supplier_document_type_id?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_document"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mb: 2, width: '100%' }}
                  id="supplier_document"
                  label="Documento"
                  error={!!errors.supplier_document}
                  helperText={errors?.supplier_document?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_email"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="supplier_email"
                  label="Email"
                  placeholder="your@email.com"
                  error={!!errors.supplier_email}
                  helperText={errors?.supplier_email?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_state"
              control={control}
              render={({ field: { onChange, value, onBlur, ref } }) => (
                <CustomAutocomplete
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  label="Estado"
                  id="supplier_state"
                  value={value || null}
                  options={statusOptions}
                  onChange={(event, newValue) => {
                    onChange(event);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option.key}>
                      {option.label}
                    </li>
                  )}
                  error={!!errors.supplier_state}
                  helperText={errors?.supplier_state?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_phone"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="supplier_phone"
                  label="Celular"
                  error={!!errors.supplier_phone}
                  helperText={errors?.supplier_phone?.message}
                  required
                />
              )}
            />
            <Controller
              name="supplier_address"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  id="supplier_address"
                  label="Dirección"
                  // value={form.cli_nombre_empresa}
                  error={!!errors.supplier_address}
                  helperText={errors?.supplier_address?.message}
                  required
                />
              )}
            />
            <></>
          </div>
          <div className=''>
            <p className="text-16 font-bold my-2">Seguridad</p>
            <Controller
              name="new_password_p"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  type={values.showPasswordNew ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword(
                            "showPasswordNew"
                          )}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPasswordNew ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  id="new_password_p"
                  autocomplete={false}
                  label="Nueva contraseña"
                  error={!!errors.new_password_p}
                  helperText={errors?.new_password_p?.message}
                  required
                />
              )}
            />
            <Controller
              name="new_password_confirm_p"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  {...field}
                  styles={{ mt: 1, mb: 2, width: '100%' }}
                  type={values.showPasswordConfirm ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {" "}
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword(
                            "showPasswordConfirm"
                          )}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPasswordConfirm ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autocomplete={false}
                  id="new_password_confirm_p"
                  label="Confirmar contraseña"
                  error={!!errors.new_password_confirm_p}
                  helperText={errors?.new_password_confirm_p?.message}
                  required
                />
              )}
            />
          </div>
          <div className="flex justify-end my-4 items-center">
            <p
              className="mx-4 underline text-base text-gray-600 cursor-pointer"
              onClick={() => {
                handleClose();
                setLoading(false);
                cleanData();
              }}
            >
              Cancel
            </p>
            {loading ? (
              <CircularProgress
                style={{ color: '#4575D5', marginRight: 4 }}
                size={24}
              />
            ) : (
              <CustomButton
                label="Guardar"
                typebutton={2}
                onClick={handleSubmit(handleAction)}
              />
            )}
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}

export default CustomDialog;
