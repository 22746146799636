import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import API from '../configs/urlsConfig';

export const getServices = () => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/lista`;
    const result = await axios.get(URL);
    await dispatch(setServicesList(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getServices *** ${error}`);
  }
};

export const getAll = (page, per_page, orderBy, order, params) => async (dispatch) => {
  try {
    const queryString = `?page=${page}&rows=${per_page}&order_field=${orderBy}&order_type=${order}&filters=${JSON.stringify(params)}`;
    const URL = `${API.baseUrl}/${API.endpoints.services}`;
    const result = await axios.get(URL + queryString);
    await dispatch(setDataList(result.data));
    return result;
  } catch (error) {
    throw new Error(`*** REDUX -> getAll *** ${error}`);
  }
};

export const create = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}`;
    const result = await axios.post(URL, data);
    return result.data;
  } catch (error) {
    throw new Error(`*** REDUX -> create *** ${error}`);
  }
};

export const update = (data, id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/${id}`;
    await axios.put(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> update *** ${error}`);
  }
};
// ------------------------------------
export const getServiceFiles = (id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/file/${id}`;
    const result = await axios.get(URL);
    await dispatch(setFiles(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getServiceFiles *** ${error}`);
  }
};

export const createServiceFiles = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/file`;
    await axios.post(URL, data, { headers: { 'Content-Type': 'multipart/form-data' } });
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createServiceFiles *** ${error}`);
  }
};

export const deleteServiceFiles = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/file_delete`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> deleteServiceFiles *** ${error}`);
  }
};
// ------------------------------------
export const getServiceActivity = (id) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/Activity/${id}`;
    const result = await axios.get(URL);
    await dispatch(setActivity(result.data));
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> getServiceActivity *** ${error}`);
  }
};

export const createServiceActivity = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/Activity`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> createServiceActivity *** ${error}`);
  }
};

export const deleteServiceActivity = (data) => async (dispatch) => {
  try {
    const URL = `${API.baseUrl}/${API.endpoints.services}/activity_delete`;
    await axios.post(URL, data);
    return true;
  } catch (error) {
    throw new Error(`*** REDUX -> deleteServiceActivity *** ${error}`);
  }
};
// ------------------------------------

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    enabledIntegration: false,
    dataList: [],
    page: 0,
    rowsPerPage: 10,
    total: 0,

    files: [],
    activity: [],
  },
  reducers: {
    setDataList: (state, action) => {
      state.dataList = action.payload.rows;
      state.total = action.payload.count;
    },
    setCleanFile: (state) => {
      state.files = [];
      state.activity = [];
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setServicesList: (state, action) => {
      state.servicesList = action.payload;
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setActivity: (state, action) => {
      state.activity = action.payload;
    },
  }
});

export const { setDataList, setRowsPerPage, setPage, setTotalRows, setServicesList, setFiles, setCleanFile, setActivity } =
  serviceSlice.actions;

export default serviceSlice.reducer;
